var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "views_active_page_plan_page bbox",
      attrs: { id: "views_active_page_plan_page" },
    },
    [
      _c(
        "div",
        {
          staticClass: "toast d-flex align-center justify-center",
          class: { activity: _vm.switch_toast },
        },
        [
          _c("div", { staticClass: "icon" }),
          _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.toast_text))]),
        ]
      ),
      _c("div", { staticClass: "wrapper bbox" }, [
        _c("div", { staticClass: "logo" }),
        _c("div", { staticClass: "form_group bbox" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-column justify-space-between form" },
            [
              _c("div", { staticClass: "info_group" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n            妙行链动-【礼遇】\n          "),
                ]),
                _c(
                  "div",
                  { staticClass: "line_group bbox d-flex align-start" },
                  [
                    _c("div", { staticClass: "key flex-shrink-0" }, [
                      _vm._v("\n              真实姓名\n            "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.name,
                          expression: "name",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text", placeholder: "请输入真实姓名" },
                      domProps: { value: _vm.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.name = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "line_group bbox d-flex align-start" },
                  [
                    _c("div", { staticClass: "key flex-shrink-0" }, [
                      _vm._v("\n              联系方式\n            "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        type: "text",
                        maxlength: "11",
                        placeholder: "请输入联系方式",
                      },
                      domProps: { value: _vm.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.phone = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "line_group bbox d-flex align-start" },
                  [
                    _c("div", { staticClass: "key flex-shrink-0" }, [
                      _vm._v("\n              您的职位\n            "),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_group",
                        attrs: {
                          clearable: "",
                          placeholder: "请选择您的职位",
                          value: "identities",
                        },
                        model: {
                          value: _vm.identities,
                          callback: function ($$v) {
                            _vm.identities = $$v
                          },
                          expression: "identities",
                        },
                      },
                      _vm._l(_vm.identities_list, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "line_group bbox d-flex align-start" },
                  [
                    _c("div", { staticClass: "key flex-shrink-0" }, [
                      _vm._v("\n              机构名称\n            "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.school,
                          expression: "school",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text", placeholder: "请输入机构名称" },
                      domProps: { value: _vm.school },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.school = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "line_group bbox d-flex align-start" },
                  [
                    _c("div", { staticClass: "key flex-shrink-0" }, [
                      _vm._v("\n              咨询内容\n            "),
                    ]),
                    _c("el-input", {
                      staticClass: "textarea",
                      attrs: {
                        type: "textarea",
                        placeholder: "请输入内容",
                        maxlength: "80",
                        "show-word-limit": "",
                        resize: "none",
                      },
                      model: {
                        value: _vm.content,
                        callback: function ($$v) {
                          _vm.content = $$v
                        },
                        expression: "content",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "submit",
                  attrs: { type: "button" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("点击咨询")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }