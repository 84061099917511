<template>
  <div
    id="views_active_page_plan_page"
    class="views_active_page_plan_page bbox"
  >
    <div class="toast d-flex align-center justify-center"
         :class="{activity: switch_toast}"
    >
      <div class="icon"></div>
      <div class="text">{{toast_text}}</div>
    </div>
    <div class="wrapper bbox">
      <div class="logo"></div>
      <div class="form_group bbox">
        <div
          class="d-flex flex-column justify-space-between form"
        >
          <div class="info_group">
            <div class="title">
              妙行链动-【礼遇】
            </div>
            <div
              class="line_group bbox d-flex align-start"
            >
              <div class="key flex-shrink-0">
                真实姓名
              </div>
              <input
                v-model="name"
                type="text"
                class="input"
                placeholder="请输入真实姓名"
              >
            </div>
            <div
              class="line_group bbox d-flex align-start "
            >
              <div class="key flex-shrink-0">
                联系方式
              </div>
              <input
                v-model="phone"
                type="text"
                class="input"
                maxlength="11"
                placeholder="请输入联系方式"
              >
            </div>
            <div
              class="line_group bbox d-flex align-start "
            >
              <div class="key flex-shrink-0">
                您的职位
              </div>
              <el-select
                v-model="identities"
                clearable
                placeholder="请选择您的职位"
                class="select_group"
                value="identities"
              >
                <el-option
                  v-for="item in identities_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="line_group bbox d-flex align-start "
            >
              <div class="key flex-shrink-0">
                机构名称
              </div>
              <input
                v-model="school"
                type="text"
                class="input"
                placeholder="请输入机构名称"
              >
            </div>
            <div
              class="line_group bbox d-flex align-start "
            >
              <div class="key flex-shrink-0">
                咨询内容
              </div>
              <el-input
                v-model="content"
                type="textarea"
                placeholder="请输入内容"
                maxlength="80"
                show-word-limit
                resize="none"
                class="textarea"
              >
              </el-input>
            </div>
            <!--<div class="line">-->
            <!--  <div class="key">真实姓名:</div>-->
            <!--  <input type="text" class="val"-->
            <!--    name="name"-->
            <!--    v-model="name"-->
            <!--    @focus="handleFocus()"-->
            <!--    placeholder="输入真实姓名"-->
            <!--  >-->
            <!--</div>-->
            <!--<div class="line">-->
            <!--  <div class="key">联系方式:</div>-->
            <!--  <input type="number" class="val"-->
            <!--    name="phone"-->
            <!--    @focus="handleFocus()"-->
            <!--    v-model="phone"-->
            <!--    placeholder="输入联系方式"-->
            <!--  >-->
            <!--</div>-->
            <!--<div class="line">-->
            <!--  <div class="key">您的职位:</div>-->
            <!--  <input type="text" class="val"-->
            <!--    name="job"-->
            <!--    v-model="job"-->
            <!--    @focus="handleFocus()" -->
            <!--    placeholder="输入您的职位"-->
            <!--  >-->
            <!--</div>-->
            <!--<div class="line">-->
            <!--  <div class="key">学校名称:</div>-->
            <!--  <input type="text" class="val"-->
            <!--    name="school"-->
            <!--    v-model="school"-->
            <!--    @focus="handleFocus()"-->
            <!--    placeholder="输入学校名称"-->
            <!--  >-->
            <!--</div>-->
          </div>
          <button
            class="submit"
            type="button"
            @click="handleSubmit"
          >点击咨询</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '@/api/global'
export default {
  mounted(){
    document.documentElement.style.fontSize = '13.333333vw';
    this.w_height = window.innerHeight;
    let height = document.documentElement.clientHeight
    window.onresize = () => { // 在页面大小发生变化时调用
      // 把获取到的高度赋值给根div
      document.getElementById('views_active_page_plan_page').style.height = height + 'px'
    }
  },
  beforeDestroy(){
    document.documentElement.style.fontSize = '14px';
  },
  data(){
    return {
      loadingPost: false,
      identities: 1,
      identities_list: [
        { value: 1, label: '教师' },
        { value: 2, label: '学生' },
        { value: 3, label: '职工' },
        { value: 4, label: '其他' }
      ],
      content: '',
      w_height: 0,
      name: '',
      phone: '',
      job: '',
      school: '',
      toast_text: '提示',
      switch_toast: false,
    }
  },
  watch: {
    phone: {
      handler: function(value){
        if(value.length>11){
          this.phone = value.slice(0, 11);
        }
      }
    }
  },
  methods: {
    handleFocus(){
      document.body.style.height = this.w_height+'px';
    },
    showToast(params){
      const {text} = params;
      this.switch_toast = true;
      if(text){
        this.toast_text = text;
      }
      setTimeout(()=>{
        this.switch_toast=false;
      }, 1500)
    },
    async handleSubmit(){
      const { content, identities, name, phone, school, loadingPost } = this
      const phoneRegExp = /^1[3456789]\d{9}$/
      // const {name, phone, job, school} = this;
      // if(name === ''){
      //   this.showToast({text: '请输入真实姓名'});
      //   return false;
      // }
      // if(phone === '' || !phoneRegExp.test(phone)){
      //   this.showToast({text: '请输入正确的手机号'});
      //   return false;
      // }
      // if(job === ''){
      //   this.showToast({text: '请输入您的职位'});
      //   return false;
      // }
      // if(school === ''){
      //   this.showToast({text: '请输入学校名称'});
      //   return false;
      // }
      // const res = await this.doSubmit();
      // if(res.message === 'success'){
      //   this.$router.push({name: 'active_page_plan_success'});
      //
      // }else{
      //   this.showToast({text: '提交失败,请稍候再试'});
      // }
      if (name.trim() === '') {
        this.showToast({text: '请输入真实姓名'})
        return
      }
      if (phone === '' || !phoneRegExp.test(phone)) {
        this.showToast({text: '请输入正确的手机号'});
        return
      }
      // if (!identities) {
      //   this.showToast({ desc: '请选择职位' })
      //   return
      // }
      if (school.trim() === '') {
        this.showToast({ text: '请输入机构名称' })
        return
      }
      if (loadingPost) {
        return
      }

      const data = {
        name,
        phone,
        identities,
        school,
        content,
        enquireType: 1,
        title: `采集-${name}-销售`
      }
      this.loadingPost = true
      const res = await global.doPost({
        url: '/projectH5/link',
        data
      })
      this.loadingPost = false
      // const res = await this.doSubmit();
      if(res.message === 'success'){
        // this.$router.push({name: 'active_page_plan_success', params: {add_more_router_name: 'active_page_laboratory'}});
        // this.$notice({
        //   desc: '提交成功',
        //   type: 'success'
        // })
        this.$router.push({name: 'active_page_plan_success'})
      }
    },
    async doSubmit(){
      const {name, phone, job, school} = this;
      const data = {
        name: `采集-${name}-销售`,
        task_type: 1,
        chat_type: 1,
        chat_id: 912,
        // to_users: '5494,193,2863,380,3762',
        to_users: '5494,193,2863,380,3762,2863,4385,4383,4382,2424,3116,4386,5555,5902,4435',
        controls: JSON.stringify([
          {"type":1,"remark":"姓名","content":name,"remindList":[],"sort":1,"fileJson":[],"sortJson":[]},
          {"type":1,"remark":"学校","content":school,"remindList":[],"sort":2,"fileJson":[],"sortJson":[]},
          {"type":1,"remark":"职务","content":job,"remindList":[],"sort":3,"fileJson":[],"sortJson":[]},
          {"type":1,"remark":"电话","content":phone,"remindList":[],"sort":4,"fileJson":[],"sortJson":[]}
        ])
      }
      // return {message: 'success'};
      this.$showLoading({title: '提交中'});
      const res = await this.$http.basic_request(
        '/projectTask/createTask',
        data,
        {
          headers: {
            loginUserCode: JSON.stringify({"code":"1634626594791100000","userId":"5494","logintype":"1"}),
          }
        }
      )
      // console.log(res);
      this.$hideLoading();
      return res;

    }
  }
}
</script>

<style lang="scss" scoped>
.views_active_page_plan_page{
  width: 100%;
  max-width: 960px;
  height: 100%;
  min-height: 100%;
  background: #E2EEFA;
  margin: 0 auto;
  position: relative;
  // padding: 40px;
  padding: {
    top: 1rem;
    bottom: 0.86rem;
    left: 0.4rem;
    right: 0.4rem;
  }
  .wrapper{
    // position: absolute;
    width: 100%;
    height: 100%;
    position: relative;
    padding: {
      top: 0.94rem;
    }
  }
  .logo{
    width: 1.68rem;
    height: 1.68rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    background: {
      image: url(~@/assets/images/active_page/plan/logo.png);
      size: 100%;
    }
  }
  .info_group{
    width: 100%;
  }
  .form_group{
    z-index: 2;
    background-color: #fff;
    // position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    // bottom: 0;
    // top: 0.94rem;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
    padding: {
      top: 0.9rem;
      bottom: 0.8rem;
      left: 0.5rem;
      right: 0.5rem;
    }
    .form{
      height: 100%;
      width: 100%;
    }
    .title{
      font-size: 24px;
      color: #0054A7;
      width: 100%;
      text-align: center;
      padding-bottom: 0.5rem;
    }
    //.line{
    //  display: flex;
    //  display: -webkit-flex;
    //  border-bottom: 2px solid #f0f0f0;
    //  height: 1.2rem;
    //  align-items: center;
    //  .key{
    //    font-size: 16px;
    //    color: #666;
    //    padding-right: 10px;
    //    line-height: 30px;
    //  }
    //  .val{
    //    flex: 1;
    //    font-size: 16px;
    //    color: #333;
    //    border: none;
    //    outline: none;
    //    line-height: 30px;
    //    &::placeholder{
    //      color: #999;
    //    }
    //  }
    //}
    .line_group{
      padding-bottom: 20px;
      .key{
        width: 80px;
        font-size: 14px;
        color: #333;
        line-height: 36px;
      }
      &.readonly{
        .input{
          border-color: transparent;
          background-color: transparent;
        }
      }
      .input{
        @include placeholder(#bbb);
        font-size: 14px;
        line-height: 34px;
        flex: 1;
        box-sizing: border-box;
        border: 1px solid #E5E7EA;
        padding: 0 10px;
        outline: none;
        background-color: #FBFBFB;
      }
      .verify{
        @include transition;
        width: 100px;
        text-align: center;
        font-size: 14px;
        color: $main_blue;
        border: 1px solid $main_blue;
        line-height: 34px;
        border-radius: 4px;
        margin-left: 10px;
        &.active{
          color: #bbb;
          border-color: #E5E7EA;
        }
      }
    }
    .submit{
      width: 100%;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
      text-align: center;
      line-height: 0.9rem;
      border-radius: 4px;
      background-color: #0054A7;
    }
  }
  .toast{
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 90%;
    background-color: rgba(0,0,0,0.7);
    border-radius: 4px;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    height: 1rem;
    padding: 0 0.2rem;
    .icon{
      width: 19px;
      height: 19px;
      background: {
        image: url(~@/assets/images/active_page/plan/error.png);
        size: 100% 100%;
      }
      margin-right: 6px;
    }
    .text{
      flex: 1;
    }
    opacity: 0;
    &.activity{
      z-index: 9999;
      opacity: 1;
    }
  }
}
</style>
<style lang="scss">
.views_active_page_plan_page{
  .readonly{
    .select_group{
      .el-input{
        &.is-focus{
          border-color: transparent!important;
        }
      }
      .el-input__inner{
        background-color: transparent!important;
        border-color: transparent!important;
      }
    }
    .textarea{
      .el-textarea__inner{
        border-color: transparent!important;
        background-color: transparent!important;
      }
    }
  }
  .select_group{
    width: 100%;
    .el-input{
      &.is-focus{
        border-color: #E5E7EA!important;
      }
    }
    .el-input__inner{
      @include placeholder(#bbb);
      border-color: #E5E7EA!important;
      padding-left: 10px!important;
      padding-right: 10px!important;
      color: #333!important;
      background-color: #FBFBFB!important;
    }
    .el-input.is-disabled .el-input__inner{
      cursor: inherit!important;
    }
  }
  .textarea{
    .el-textarea__inner{
      @include placeholder(#bbb);
      border-color: #E5E7EA!important;
      color: #333!important;
      padding-left: 10px!important;
      padding-right: 10px!important;
      font-family: 'MicroSoft YaHei',sans-serif!important;
      line-height: 20px!important;
      font-size: 14px!important;
      padding-top: 8px!important;
      padding-bottom: 8px!important;
      background-color: #FBFBFB!important;
    }
  }
}
</style>
